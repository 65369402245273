<template>
	<main class="d-flex flex-row">
		<v-card 
			v-if="visible.preview" 
			class="mx-2" 
			width="100%" 
			style="height:100vh; overflow:scroll;" 
			scrollable
		>
			<v-card-text style="position:absolute;">
				<v-skeleton-loader v-if="table_loading||loading" height="100%" type="table"/>
				<v-container v-else fluid fill-heigh> 
					<div class="text-left my-5" row>
						<h1 color="black">{{ output_name }}</h1>
					</div>
					<div class="my-5" row>
						<BuilderDataTable :loading="loading"/>
					</div>
				</v-container>
			</v-card-text>
		</v-card>

		<v-card 
			v-if="visible.builder" 
			class="mx-2" 
			style="height:100vh;" 
			width="45%"
		>
			<v-card-text style="height:75vh; overflow-y:scroll;">
				<v-skeleton-loader 
					v-if="loading" 
					class="mt-5" 
					height="150" 
					width="250" 
					type="article, actions"
				/>
				<template v-else>
					<BuilderDefaultAttribute/>
					
					<!-- Attributes -->
					<template v-for="(value, key) in columns">
						<BuilderCustomAttribute :key="key" :index="key" />
					</template>  
					<!-- ../Attributes -->
				</template>
			</v-card-text>
		
			<!-- Add Button for Column -->
			<v-card v-if="!loading" style="height:5vh; top:20px;" 
			class="mx-2 my-2 d-flex flex-row align-center justify-space-around">
				<h3>Column</h3>
				<v-btn v-if="hasDateRange" @click="onAddColumn()" icon>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-card>
			<!-- ../Add Button for Column -->

			<!-- Generate preview button -->
			<v-btn 
				v-if="hasDateRange && hasColumns" 
				:loading="table_loading" 
				@click="onGeneratePreview()" 
				class="my-12" 
				color="primary"
			>
				Generate Preview
			</v-btn>
			<!-- ../Generate preview button -->

		</v-card>

		<!-- yielding the header component into layout component -->
		<portal  to="query-builder-header">
			<BuilderHeaders/>
		</portal>

		<BuilderConfirmModal v-if="dialog"/>
	</main>
</template>

<script>
	import { mapActions, mapMutations, mapGetters } from "vuex";
	
	import BuilderDefaultAttribute from "@/modules/QueryBuilder/Builder/components/BuilderDefaultAttribute.vue";
	import BuilderCustomAttribute from "@/modules/QueryBuilder/Builder/components/BuilderCustomAttribute.vue";
	import BuilderConfirmModal from "@/modules/QueryBuilder/Builder/components/BuilderConfirmModal";
	import BuilderDataTable from "@/modules/QueryBuilder/Builder/components/BuilderDataTable.vue";
	import BuilderHeaders from "@/modules/QueryBuilder/Builder/components/BuilderHeaders.vue";

	export default {
		name:"templates",
		components:{
			BuilderDefaultAttribute, 
			BuilderCustomAttribute, 
			BuilderDataTable, 
			BuilderConfirmModal, 
			BuilderHeaders
		},

		data(){
			return{
				currentpath:null,
				currentpathquery:null,
				pagination:{
		            page:1,
		            itemsPerPage:10,
		            loading:false,
		        },
			}
		},

		created(){ 
			this.initialize(); 
		},

		beforeDestroy(){	
			this.setFormEditable(false);
			this.setVisibility({ 
				builder:true, 
				preview:true, 
				header:{ type:"add", } 
			});
		},

		methods:{

			...mapActions("builder",[
				"getTemplate",
				"setCreateTemplate",
				"setUpdateTemplate",
				"setFormEditable",
				"addColumn",
				"setVisibility",
				"clearForm",
				"getGenerateReport",
				"getCalendarHolidays"
			]),

			/*
			* To set initialization
			*/
			async initialize(){
				 
				// clear form 
				this.clearForm();

				// get records for multiple values 
				this.fetchRecords();

				// get the current path of query builder form 
				this.currentpath = this.$route.path;

				// get the query param on current route
				this.currentpathquery = this.$route.query;

				// check if there's id parameter from query 
				if(this.$route.query.hasOwnProperty("id")){	
					// get template by id from query parameter
					await this.getTemplate(this.$route.query.id);
				}
			},

			/*
			* for multiple attribute column
			*/
			fetchRecords() {
				this.$store.dispatch(`accounts/fetchRecords`);
				this.$store.dispatch(`sites/fetchRecords`);
				this.$store.dispatch(`assignments/fetchRecords`);
				this.$store.dispatch(`schedules/fetchRecords`);
				this.$store.dispatch(`employee-roles/fetchRecords`);
			},

			/*
			* to add column
			*/
			onAddColumn(){
				this.addColumn({ type:null, value:null });
			},

			/*
			* to generate preview from added columns  
			*/
			async onGeneratePreview(){
				let form = await this.form;
				
				// google calendar API
				this.getCalendarHolidays(_.get(form,"column_attributes.from"));

				if(form.hasOwnProperty("column_attributes")){
					if(form.column_attributes.hasOwnProperty("columns")){
						let columns = await form.column_attributes.columns.filter(data=>data.type !=null);
						await this.getGenerateReport({ 
							...this.pagination,
							columns:columns, 
							from:this.form.column_attributes.from, 
							to:this.form.column_attributes.to 
						});
					}
				}
			},
		},

		computed:{
			...mapGetters("builder",{ 
				"columns":"getColumns", 
				"loading":"getLoading",
				"form":"getForm",
				"visible":"getVisibility"
			}),

			...mapGetters("query_builder_modal",{ 
				"dialog":"getDialog", 
				"table_loading":"getTableLoading" 
			}),

			...mapGetters("builder",{ 
				"table_loading":"getTableLoading",
			}),
			
			hasDateRange(){
				return this.form.column_attributes.from != null && this.form.column_attributes.to != null ;
			},

			hasColumns(){
				return this.form.column_attributes.columns.length > 0;
			},

			output_name(){
				return this.form.column_attributes.output_name;
			},
		},
	}
</script> 
