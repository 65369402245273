<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="800px" @keydown.esc="close()">
         <v-card :loading="isSubmit">
            <v-card-title>
               <span class="font-weight-bold">{{ title }}</span>
               <v-spacer/>
               <span class="caption mr-3">Press</span>
               <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
               <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
       	   </v-card-title>
            <v-divider/>
            <v-card-text>
     		      <v-form  v-model="valid" ref="form" @keyup.native.enter="submit(mode)">
                  <v-container fluid>
                     <v-row no-gutters class="mx-2">
                        <v-col>
                           <v-row no-gutters style="max-height:100px!important;">
                              <label class="d-block text-left font-weight-bold label">
                                 Organization
                              </label>
                           </v-row>
                           <v-row no-gutters style="max-height:60px!important;">
                              <v-organization
                                 :organization.sync="form.organization_id"
                                 :disabled="AuthRole == 'Admin'||isEdit||isDelete||isSubmit"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                     <v-row no-gutters class="mx-2">
                        <v-col>
                           <v-row no-gutters>
                              <label class="d-block text-left font-weight-bold label">
                                 Site
                              </label>
                           </v-row>
                           <v-row no-gutters style="max-height:60px!important;">
                              <v-site 
                                 :multiple="true"
                                 :site.sync="form.site"
                                 :disabled="isDelete||isSubmit"
                                 :placeholder="'Assign this site to an mobile admin'"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                     <v-row no-gutters class="mx-2">
                        <v-col>
                           <v-row no-gutters >
                              <label class="d-block text-left font-weight-bold label">
                                 Photo
                              </label>
                           </v-row>
                           <v-row no-gutters style="max-height:60px!important;">
                               <v-file-input
                                 dense
                                 outlined
                                 prepend-icon=""
                                 v-model="avatar"
                                 accept="image/jpeg"
                                 placeholder="Upload a photo"
                                 @input="error.clear('avatar')"
                                 @change="image_upload($event,'avatar')"
                                 :rules="[rules.avatar.type]":disabled="isSubmit||isDelete"
                                 :error-messages="error.get('avatar')"
                                 :error="error.has('avatar')"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                     <v-row 
                        no-gutters
                        v-if="mode == 'edit' || mode == 'delete'"
                        style="max-height: 100px!important;"
                     >
                        <v-col>
                           <v-row no-gutters class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 User ID
                              </label>
                           </v-row>
                           <v-row no-gutters class="ma-2">
                              <v-text-field  
                                 dense
                                 disabled
                                 outlined
                                 clearable
                                 v-model="form.id"
                                 placeholder="User ID" 
                              />
                           </v-row>
                        </v-col>
                        <v-col>
                           <v-row no-gutters class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 Face
                              </label>
                           </v-row>
                           <v-row no-gutters class="ma-2">
                              <v-file-input
                                 dense
                                 outlined
                                 accept="image/jpeg,image/jpg"
                                 placeholder="Upload a face bio"
                                 prepend-icon=""
                                 v-model="facebio" 
                                 @change="image_upload($event,'face_biometrics')"
                                 @input="error.clear('facebio')"
                                 :rules="[rules.facebio.type]"
                                 :disabled="isSubmit||isDelete"
                                 :error-messages="error.get('facebio')"
                                 :error="error.has('facebio')"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                     <v-row  style="margin-top:50px;" class="row my-auto mx-2">
                        <span class="d-block text-left bottom-note">
                           Mobile Admin accounts have limited access to Abizo DTR platform.
                        </span> 
                     </v-row>
                     <v-row no-gutters style="max-height: 100px!important;">
                        <v-col>
                           <v-row no-gutters class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 First Name
                              </label>
                           </v-row>
                           <v-row no-gutters class="ma-2">
                              <v-text-field  
                                 dense
                                 outlined
                                 clearable
                                 v-model="form.firstname"
                                 placeholder="Type here"
                                 @input="error.clear('firstname')"
                                 @keydown="rules.stringOnly"
                                 :disabled="isSubmit||isDelete"
                                 :error-messages="error.get('firstname')"
                                 :error.sync="error.has('firstname')"
                                 :rules="[
                                    rules.first_name.required,
                                    rules.first_name.format
                                 ]"
                              />
                           </v-row>
                        </v-col>
                        <v-col>
                           <v-row no-gutters class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 Last Name
                              </label>
                           </v-row>
                           <v-row no-gutters class="ma-2">
                              <v-text-field  
                                 dense
                                 outlined
                                 clearable
                                 v-model="form.lastname"
                                 placeholder="Type here"
                                 @input="error.clear('lastname')"
                                 @keydown="rules.stringOnly"
                                 :disabled="isSubmit||isDelete"
                                 :error-messages="error.get('lastname')"
                                 :error.sync="error.has('lastname')"
                                 :rules="[
                                    rules.last_name.required,
                                    rules.last_name.format
                                 ]"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                     <v-row no-gutters style="max-height: 100px!important;">
                        <v-col>
                           <v-row class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 Middle Name
                              </label>
                           </v-row>
                           <v-row class="ma-2">
                              <v-text-field  
                                 dense
                                 outlined
                                 clearable
                                 v-model="form.middlename"
                                 placeholder="Type here"
                                 @input="error.clear('middlename')"
                                 @keydown="rules.stringOnly"
                                 :disabled="isSubmit||isDelete"
                                 :error-messages="error.get('middlename')"
                                 :error.sync="error.has('middlename')"
                                 :rules="[
                                    rules.middle_name.required,
                                    rules.middle_name.format
                                 ]"
                              />
                           </v-row>
                        </v-col>
                        <v-col>
                           <v-row class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 Suffix
                              </label>
                           </v-row>
                           <v-row class="ma-2">
                              <v-text-field  
                                 dense
                                 outlined
                                 clearable
                                 v-model="form.suffix"
                                 placeholder="Type here"
                                 @input="error.clear('suffix')"
                                 @keydown="rules.stringOnly"
                                 :disabled="isSubmit||isDelete"
                                 :error-messages="error.get('suffix')"
                                 :error.sync="error.has('suffix')"
                                 :rules="[
                                    rules.suffix.required,
                                    rules.suffix.format
                                 ]"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                     <v-divider class="mx-2"></v-divider>
                     <v-row no-gutters>
                        <v-col>
                           <v-row class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 Email Address
                              </label>
                           </v-row>
                           <v-row class="ma-2">
                              <v-text-field  
                                 dense
                                 outlined
                                 clearable
                                 v-model="form.email_address"
                                 placeholder="Type here"
                                 @input="error.clear('email_address')"
                                 :disabled="isSubmit||isDelete"
                                 :error-messages="error.get('email_address')"
                                 :error.sync="error.has('email_address')"
                                 :rules="[
                                    rules.email.required,
                                    rules.email.format
                                 ]"
                              />
                           </v-row>
                        </v-col>
                        <v-col>
                           <v-row class="ma-2">
                              <label class="d-block text-left font-weight-bold label">
                                 Employee Id
                              </label>
                           </v-row>
                           <v-row class="ma-2">
                              <v-text-field  
                                 dense
                                 outlined
                                 clearable
                                 v-model="form.employee_id"
                                 placeholder="Type here"
                                 @input="error.clear('employee_id')"
                                 :disabled="isSubmit||isDelete"
                                 :error-messages="error.get('employee_id')"
                                 :error.sync="error.has('employee_id')"
                                 :rules="[
                                    rules.employee_id.required,
                                    rules.employee_id.format
                                 ]"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                  </v-container>
   	         </v-form>
            </v-card-text>
            <v-divider></v-divider>
            
            <!-- -------------------- CARD ACTIONS ------------------- -->
            <v-card-actions class="ma-4">
               <!-- ------------- DELETE OR DISCARD ------------- -->
               <v-btn
                  dark
                  outlined
                  class="mx-2 elevation-0 font-weight-bold custom-card-button"  
                  @click="mode == 'delete' ? submit(mode) : close()"
                  :color="mode == 'delete'?'red':'#0057AD'"
                  :loading="mode == 'delete'? isSubmit : false"
               >
                  {{ mode == 'delete' ? 'Delete':'Discard'}} 
               </v-btn>
               <v-spacer></v-spacer>
               <!-- ------------- CANCEL OR SUBMIT ------------- -->
               <v-btn 
                  dark
                  color="#0057AD"
                  class="mx-2 elevation-0 font-weight-bold custom-card-button" 
                  @click="mode == 'delete' ? close():submit(mode)"
                  :loading="mode == 'delete'? false : isSubmit"
               >
                  {{ mode == 'delete' ? 'Cancel':'Submit' }}
               </v-btn> 
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</template>

<script>

import { rules }  from "@/validation/rules/index";
import { modal }  from "@/mixins/modal";
import { auth }   from "@/mixins/auth";
import { states } from "@/mixins/states";
import  Errors  from "@/validation/errors";

import VOrganizaion from "@/components/Fields/Autocomplete/Organization/Index";
import VSite from "@/components/Fields/Autocomplete/Site/Index";

export default {
	mixins:[modal,auth,states],
	name:"index",
   components:{
   "v-organization":VOrganizaion,
   "v-site":VSite
   },
	data(){ 
		return {

         // to check if the field were all valid
         valid: false,

         // to validate the fields 
         rules:rules,

         // to set the data event Add or Edit
         form:{},

         // errors: new Errors()
         error:new Errors(),

         // to check if loading or not 
         isLoading: false,

         // to check if action is submitting
         isSubmit: false,

         // to check if action is editting
         isEdit: false,

         // to check if mode is delete
         isDelete:false,

         // to reset the value from form.avatar
         avatar:null,

         // to reset the value from form.facebio
         facebio:null
		}
	}, 

   mounted(){
      this.initilize();
   },

   updated(){
      this.setActions();
      this.setAuthOrganizationId();
   },

   methods:{      

      /*
      * This initilize value values needed
      *
      * @return void
      */ 
      initilize(){
         this.setValues();
         this.setAuthOrganizationId();
      },

      /*
      * To get image into base64 string 
      *
      * @return void
      */ 
      image_upload(event,type){
         try {
            if(typeof null != event){
               // new instance of FileReader class 
               const reader = new FileReader();
               
               if(type == "avatar"){
                  // get the base64 from result from File Reader
                  reader.onloadend = ((image) =>{ this.form.avatar  =  reader.result; });
               }

               else if(type == "face_biometrics"){
                  // get the base64 from result from File Reader
                  reader.onloadend = ((image) =>{ this.form.facebio =  reader.result; });
               }

               // read the data as url from upload payload file
               reader.readAsDataURL(event);
            }
         }

         catch(e){
            if(!(e instanceof TypeError)){
               // The exception is not a TypeError, so throw it again.
               throw e;
             }
         }
      },

      /*
      * This assign value values to the form object
      *
      * @return void
      */
      setValues(){
         Object.assign(this.form,this.data);
         this.form.site = this.data && this.data.site ? this.data.site.map((data)=>{ return data.id }) : null;
      },

      /*
      * This assign organization id coming from organization admin
      *
      * @return void
      */
      setAuthOrganizationId(){
         if(this.AuthRole == "Admin"){
            this.form.organization_id = this.AuthOrganization.id;
         }
      },

      /*
      * This set actions based on modal action mode
      *
      * @return void
      */
      setActions(){
         this.isDelete = this.mode == "delete" ? true : false;
         this.isEdit   = this.mode == "edit" ? true : false;
      },

      /*
      * To submit values for Add,Edit,Delte
      *
      * @return void
      */  
      submit(action = "add"){
         switch(action){
            case "add": 
               if(this.$refs.form.validate()){
                  this.isSubmit = true;
                  this.$store.dispatch("setRegisterMobileAdmin",this.form)
                  .then((response) => {this.isSubmit = false; this.redirect("/mobileadmins");})
                  .then((response) => {this.close()})
                  .catch((errors)  => {
                     this.error.record(errors.response.data);
                     this.isSubmit = false; 
                  })
                  .finally(() => {});
               }
            break;

            case "edit": 
               if(this.$refs.form.validate()){
                  this.isSubmit = true;
                  this.$store.dispatch("setUpdateMobileAdmin",this.form)
                  .then((response) => {this.isSubmit = false})
                  .then((response) => {this.close()})
                  .catch((errors)  => { 
                     this.error.record(errors.response.data);
                     this.isSubmit = false; 
                  })
                  .finally(() => {});
               }
            break;

            case "delete": 
                  this.isSubmit = true;
                  this.$store.dispatch("setDeleteMobileAdmin",this.form)
                  .then((response) => {this.isSubmit = false})
                  .then((response) => {this.close()})
                  .catch((errors)  => {})
                  .finally(() => {});
            break;
         } 
      },
   },

   watch:{

      /*
      * Resets the form.avatar field form
      *
      * @return void
      */
      avatar(value){
         this.form.avatar = value;
      },

      /*
      * Resets the form.facebio field form
      *
      * @return void
      */
      facebio(value){
         this.form.facebio = value;
      },

      /*
      * Resets the field form
      *
      * @return void
      */ 
      dialog(value){
         if(!value){
            this.$refs.form.reset();
            setTimeout(()=>{ this.form = {} },1000);
         }
      },
	}
}

</script>

<style type="text/css" scoped>

   .custom-card-button{
      height:40px;
      width:150px;
   }

   .label {
      color:black;
      font-size: 16px;
   }

   .img-format-label {
      color:#BDBDBD; 
      font-size: 13px;
   }

   .bottom-note{
      color:#BDBDBD; 
      font-size: 13px; 
      margin-top: -20px;
   }

</style>