<template>
	<v-app>
		<section>
			<div class="left-background"></div>

			<div class="col-lg-4 col-lg-3 col-md-6 col-md-4 col-sm-8 col-sm-6 col-xs-8 col-xs-4 right-background">

				<!-- ------------------------------- LOGO  ------------------------------- -->
				<img class="abizo_logo" :src="logo" style="margin-top:150px; width:80px"/>

				<v-form id="login" ref="form" v-model="valid" @keyup.native.enter="login">
					
					<v-alert v-model="alert" dense outlined dismissible type="error" class="mx-auto" transition="scroll-y-transition">
				    	{{ error_message }}
				    </v-alert>

					<!-- ---------------------------- EMAIL FIELD ---------------------------- -->
					<v-text-field class="centered-input" 
					outlined dense  placeholder="Email Address" 
					clearable style="margin:20px 85px 0px 85px;"
					v-model="form.email" 
					autocomplete="username"
					:rules="[rules.email.required,rules.email.format]"
					:error-messages="email.error" 
					@keydown="email.error = '' ">
					</v-text-field>

	            	<!-- --------------------------- PASSWORD FIELD -------------------------- -->
					<v-text-field  class="centered-input password"  
					outlined dense placeholder="Password" 
					:type="show_pass ? 'text' : 'password'" clearable  
					:append-icon="show_pass ? 'mdi-eye-off': 'mdi-eye'" 
					@click:append ="show_pass = !show_pass" 
					autocomplete="current-password"
					style="margin:0px 85px 0px 85px;"
					:rules="[rules.password.required]" 
					:error-messages="email.error"
					v-model="form.password"  @keydown="password.error = ''">
					</v-text-field>

	            <!-- ---------------------------- LOGIN BUTTON --------------------------- -->

	            <v-btn class="primary" style="margin:20px;" @click="login">Login to Zabi DTR</v-btn>

	            <!-- ---------------------------- CHECKBOX ------------------------------- -->

	            <!-- <v-checkbox  style="margin:0px 85px 0px 85px;" color="primary" label="Remember Me"></v-checkbox> -->

            	</v-form>
			</div>

			<footer style="position:fixed; top:95%; color:#FFFFFF;" >
				&copy; {{ new Date().getFullYear() }} Zabi Inc. All rights reserved.
		    </footer>
		</section>
	</v-app>
</template>

<script type="text/javascript">

import { rules } from "@/validation/rules/index";
import  Errors  from "@/validation/errors";
	export default
	{	
		name:"index",
		data() {
	        return {
	        	// to check if the field were all valid
	        	valid:false,

	        	// to show or hide password 
	        	show_pass:false,

	        	// abizo dtr logo
				logo:require('@/assets/images/brand/zabi_dtr_logo.png'),

				// to validate the fields 
				rules:rules,

				// set values here 
				form:{},

				alert:false,

				error_message:"",

				// required fields 
				email:{
					error:"",
				},

				password:{
					error: ""
				}
	        }
		},

		methods:{
			/*
	 		* To login the user with email and password
	 		*
	 		* @return void
	 		*/ 
			login(){

				this.$Progress.start();

				if(this.$refs.form.validate())
				{
			    	this.$store.dispatch("login",{ email:this.form.email, password:this.form.password })
			    	.then((response)=>{
						if(response.status == 200){
							this.$router.push('/')
						} 
			    	})
			    	.catch((errors)=>{
			 		
		 				this.alert = true;
		    			this.error_message = errors
			    	})
				}
			}
		},
	}

</script>

<style type="text/css">

	body, html
	{
	  height: 100%;
	}

	.left-background
	{
		background-color: #0057AD; position: fixed; top:0; left:0; width:100%; height: 100%;
	}

	.right-background
	{
		background-color:white; position:fixed; top:0; right:0;  height:100%;
	}

</style>