<template>
   <!-- -------------- MODAL: MAP ------------------- -->
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="800px" @keydown.esc="close()">
         <v-card>

            <!-- -------------------- CARD HEADER ------------------- -->
            <v-card-title>
               <span class="font-weight-bold">{{ form.title }}</span>
               <v-spacer></v-spacer>
               <span  class="caption mr-3">Press</span>
               <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
               <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <!-- -------------------- CARD CONTENTS ------------------- -->
            <v-card-text>
               <v-row v-if="isNotManualEntry">
                  <v-col class="mx-auto">
                     <!-- -------------- GOOGLE MAP -------------- -->
                     <gmap-map class="mt-4" :center="{ lat:form.latitude , lng:form.longitude }" :zoom="15" style="width:100%; height:200px;">
                        <gmap-marker :position="{ lat:form.latitude , lng:form.longitude }" 
                        :icon="form.type == 'timein' ? timein_mark : timeout_mark" ></gmap-marker>
                     </gmap-map>

                     <!-- -------------- LOCATION LABEL AND GOOGLE REDIRECT -------------- -->
                     <v-row class="d-flex justify-content space-between mx-auto my-1">
                        <label class="d-block text-left font-weight-bold subtitle-1 my-1" style="color: black;"> Location </label>
                        <v-spacer></v-spacer>
                        <a class="d-block text-right subtitle-1 my-1" target="_blank"  @click="google_map()">Open in Google Maps</a>
                     </v-row>

                     <!-- -------------- LOCATION -------------- -->
                     <v-chip class="d-block text-left my-1" label outlined large>{{ form.location }}</v-chip>

                  </v-col>
               </v-row>
               
               <v-row v-if="form.latitude != 0 && form.longitude != 0">
                  <!-- -------------- LONGITUDE -------------- -->
                  <v-col>
                     <label class="d-block text-left font-weight-bold subtitle-1 my-1" style="color: black;"> Longitude </label>
                     <v-chip class="d-block text-left" label outlined large>{{ form.longitude }}</v-chip>
                  </v-col>

                  <!-- -------------- LATITUDE -------------- -->
                  <v-col>
                     <label class="d-block text-left font-weight-bold subtitle-1 my-1" style="color: black;"> Latitude </label>
                     <v-chip class="d-block text-left" label outlined large>{{ form.latitude }}</v-chip>
                  </v-col>
               </v-row>

               <v-row>
                  <!-- -------------- DATE -------------- -->
                  <v-col>
                     <label class="d-block text-left font-weight-bold subtitle-1 my-1" style="color: black;"> Server Date </label>
                     <v-chip class="d-block text-left" label outlined large>{{ form.server_date }}</v-chip>
                  </v-col>

                  <!-- -------------- TIME -------------- -->
                  <v-col>
                     <label class="d-block text-left font-weight-bold subtitle-1 my-1" style="color: black;"> Server Time </label>
                     <v-chip class="d-block text-left" label outlined large>{{ form.server_time }}</v-chip>
                  </v-col>
               </v-row>

               <v-row>
                  <!-- -------------- DATE -------------- -->
                  <v-col>
                     <label class="d-block text-left font-weight-bold subtitle-1 my-1" style="color: black;"> Date </label>
                     <v-chip class="d-block text-left" label outlined large>{{ form.date }}</v-chip>
                  </v-col>

                  <!-- -------------- TIME -------------- -->
                  <v-col>
                     <label class="d-block text-left font-weight-bold subtitle-1 my-1" style="color: black;"> Time </label>
                     <v-chip class="d-block text-left" label outlined large>{{ form.time }}</v-chip>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <!-- -------------------- CARD ACTIONS ------------------- -->
            <v-card-actions class="ma-4">

               <!-- -------------------- AVATAR ------------------- -->
               <v-avatar>
                  <img :src="form.avatar ? form.avatar : default_avatar" alt="avatar">
               </v-avatar>

               <!-- -------------------- NAME ------------------- -->
               <span class="mx-2">{{ form.name }}</span>
               <v-spacer></v-spacer>

               <v-btn 
               class="mx-2 elevation-0 font-weight-bold" 
               style="height:35px; width:130px;" 
               :disabled="!form.name" 
               color="#0057AD" 
               @click="view_logs()">
                  <span style="color:white">View Logs</span>
               </v-btn>
            </v-card-actions>

         </v-card>
         <v-divider></v-divider>
      </v-dialog>
   </v-row>
</template>

<script>

import TimeLogsMap from "@/components/Form/Modal/Timelog/Map/Index";
import GoogleMap from "@/components/GoogleMap/Index";
import { rules } from "@/validation/rules/index";
import { modal } from "@/mixins/modal";

export default {
   mixins:[modal],
   components:{
      "v-timelog-map":TimeLogsMap,
      "google-map":GoogleMap
   },
   data(){ 
      return {
         header:[
            { text: "Date",     value: "avatar", },
            { text: "Time In",  value: "avatar", },
            { text: "Time Out", value: "type" },
            { text: "Duration (HOURS)", value: "date" },
         ], 
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         timein_mark:require('@/assets/images/mark/time_in_mark.png'),
         timeout_mark:require('@/assets/images/mark/time_out_mark.png'),
         location_loading:false,
         isNotManualEntry:false,
         form:{},
         view_logs_data:{}
      }
   }, 

   created()
   { 
      // initialize organization list for dropdown seletion
      this.initilize(this.data);
   },

   beforeDestroy(){
      this.form = {}
   },
   
   methods:{   

      /*
      * This initilize value for table view
      *
      * @return array /store/modules/organization/index getOrganizations
      */ 
      initilize(data)
      {
         // Modal title either it's a time in or time out  
         this.form.title = data.type =="timein" ? "TIME IN" : "TIME OUT";
         
         // dtr type "timein/timeout"
         this.form.type =  data.type;

         // Ex. February 6, 2020
         this.form.date = moment(data.timestamp).format("LL");
         
         // Ex. 11:05:50 AM
         this.form.time = moment(data.timestamp).format("LTS");

         // Ex. February 6, 2020
         this.form.server_date = data.server_timestamp != "0000-00-00 00:00:00" 
         ? moment(data.server_timestamp).format("LL") : 0;
            
         // Ex. 11:05:50 AM
         this.form.server_time = data.server_timestamp != "0000-00-00 00:00:00" 
         ? moment(data.server_timestamp).format("LTS") : 0;
         
         // combining the firstname and lastname of employee 
         this.form.name = data.employee ? data.employee.firstname+" "+data.employee.lastname : null;

         // the latitude and longitude parsing  text value into float value
         this.form.latitude  = parseFloat(data.latitude);
         this.form.longitude = parseFloat(data.longitude);
         this.form.location  = data.location;

         // set to display the avatar/photo of user 
         this.form.avatar = data.image && data.image.avatar ? data.image.avatar : this.default_avatar;
         this.view_logs_data = data;

         // if the latitude and longitude both not equal to zero
         if(parseFloat(data.latitude) != 0 && parseFloat(data.longitude) != 0 )
         {
            this.isNotManualEntry = true;
         }
      },

      /*
      * This to redirect to google map with new tab in browser
      *
      * @return void
      */ 
      google_map()
      {  
         window.open(`https://www.google.com/maps?z=12&t=m&q=loc:${this.form.latitude}+${this.form.longitude}`,true)
      },

      /*
      * to show modal for individual daily time logs
      *
      * @return void
      */ 
      view_logs(data)
      {  
         this.$store.dispatch("modal",{ title:"Logs", data:this.view_logs_data, mode:"view", view:"modal-timelog-logs" });
      },
   },

   watch:{
      /*
      * Get value from store using getter
      *
      * @return array getTimeLogsList
      */ 
      data(value)
      {
         this.initilize(value);
      },
   },

   computed:{

      /*
      * Get value from store using getter
      *
      * @return array getTimeLogsList
      */ 
      location()
      {
         return this.$store.getters.getLocation;
      },
   },
}

</script>
